"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _SelectInput = _interopRequireDefault(require("./SelectInput"));
var _Input = _interopRequireDefault(require("./Input"));
var _JsonInput = _interopRequireDefault(require("./JsonInput"));
var _Switch = _interopRequireDefault(require("./Switch"));
var _Radio = _interopRequireDefault(require("./Radio"));
var _SearchInput = _interopRequireDefault(require("./SearchInput"));
var _Checkbox = _interopRequireDefault(require("./Checkbox"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Input["default"].Switch = _Switch["default"];
_Input["default"].Dropdown = _SelectInput["default"];
_Input["default"].Json = _JsonInput["default"];
_Input["default"].Radio = _Radio["default"];
_Input["default"].Search = _SearchInput["default"];
_Input["default"].Select = _SelectInput["default"];
_Input["default"].Checkbox = _Checkbox["default"];
var _default = _Input["default"];
exports["default"] = _default;