"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pxToDp = pxToDp;
exports.remToDp = remToDp;
exports.vhToDp = vhToDp;
exports.vwToDp = vwToDp;
var _reactNative = require("react-native");
function pxToDp(pxValue) {
  return _reactNative.PixelRatio.roundToNearestPixel(pxValue / _reactNative.PixelRatio.get());
}
function remToDp(remValue) {
  var baseFontSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  var dpValue = remValue * _reactNative.PixelRatio.getFontScale() * baseFontSize;
  return _reactNative.PixelRatio.roundToNearestPixel(dpValue);
}
function vwToDp(vwValue) {
  var dpValue = vwValue * _reactNative.Dimensions.get("window").width / 100;
  return _reactNative.PixelRatio.roundToNearestPixel(dpValue);
}
function vhToDp(vhValue) {
  var dpValue = vhValue * _reactNative.Dimensions.get("window").height / 100;
  return _reactNative.PixelRatio.roundToNearestPixel(dpValue);
}